import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StyledText } from 'components/GlobalStyle'
import ContentWrapper from '../ContentWrapper'

const MetatresureIntroduction: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <ContentWrapper title={intl.formatMessage({ id: "label-metaTreasure-intro", defaultMessage: "MetaTreasure Introduction" })}>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' mt={32} mb={16}>
          <FormattedMessage id='hiw-1-title-1' defaultMessage='What Do We Do' />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={16}>
          <FormattedMessage id='hiw-1-content-1'
            defaultMessage="MetaTreasure is an exciting NFT collection lifetime membership where treasure hunters are able to collect rare collectible NFTs from our T-Boxes. There are a total of 4 different T-Boxes to choose from each with its own unique rewards and prizes."
          />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={25}>
          <FormattedMessage id='hiw-1-content-2'
            defaultMessage="MetaTreasure's ultimate membership experience is here, with MetaTreasure we are able to bridge the up and coming NFT blockchain technology and real life benefits. All MetaTreasure hunters will be able to benefit from our exclusive rewards and discounts from all our participating merchants and their outlets."
          />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={25}>
          <FormattedMessage id='hiw-1-content-3'
            defaultMessage="Additionally, MetaTreasure also offers a special activation NFT card which allows all minters to pair their activation card with their respective T-Boxes and receive a once in a lifetime airdrop of our MetaTreasure Token (MTT)."
          />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' mt={32} mb={16}>
          <FormattedMessage id='hiw-1-title-2' defaultMessage='How Does It Work' />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={16}>
          <FormattedMessage id='hiw-1-content-4'
            defaultMessage="All you have to do is to mint a MetaTreasure T-Box and wait for our T-Box Unboxing which is scheduled as soon as all the T-boxes in the category are minted. In each round, users will be able to discover exciting “Treasures” both in the crypto space and the real world."
          />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={25}>
          <FormattedMessage id='hiw-1-content-5'
          defaultMessage="Each T-Box will double as a lifetime membership for all the holders. While in possession of a MetaTreasure T-Box you will be able to receive amazing deals, exclusive discounts and even redeem various rewards from all our participating merchants! Check out our merchant list to see more benefits!"
          />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={25}>
          <FormattedMessage id='hiw-1-content-6'
            defaultMessage="Our list of participating merchants is ever growing and you will only stand to receive even MORE benefits! Just a one time off payment of from $10 to $1,000 to mint your very own MetaTreasure Membership NFT and that's it, no subscription required."
          />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={25}>
          <FormattedMessage id='hiw-1-content-7'
            defaultMessage="To participate in our special MTT airdrop, you will have to mint your own NFT activation card to pair with your respective T-Boxes, once successful you will be entitled to a free MTT airdrop set to be released overtime & also a complimentary gift worth $118!"
          />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={25}>
          <FormattedMessage id='hiw-1-content-8'
            defaultMessage="With MetaTreasure, there are always more surprises and special rewards so be sure to stay tuned! Who knows you may be able to receive your very own legendary treasure!"
          />
        </StyledText>
      </ContentWrapper>
    </>
  )
}

export default MetatresureIntroduction