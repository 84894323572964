import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StyledText } from 'components/GlobalStyle'
import ContentWrapper from '../ContentWrapper'

const AnnualCommonPrizePool: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <ContentWrapper title={intl.formatMessage({ id: 'hiw-5-title', defaultMessage: 'Annual Common Prize Pool' })}>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id='hiw-5-content-1' defaultMessage="MetaTreasure has also implemented an Annual Common Prize Pool reward system. In this Annual Common Prize Pool, a grand sum of <whiteSpan>369,000 USD</whiteSpan> will be up for hunters to grab. This reward will only occur once <whiteSpan>all of our T-BOXes 1, 2, 3, and 4 are fully minted</whiteSpan>."
            values={{
              whiteSpan: chunk => <span style={{ color: 'var(--whiteColor)' }}>{chunk}</span>
            }}
          />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id='hiw-5-content-2' defaultMessage="Hunters must accumulate a set of 4 NFT ending with either an <whiteSpan>Odd</whiteSpan> or <whiteSpan>Even</whiteSpan> number digital Token ID from each individual T-BOX Category. This means hunters will need to <whiteSpan>collect 4 T-BOXes from different categories</whiteSpan> based on either an Odd or Even number set. Once all 4 are matched hunters will be entitled to the Annual Common Prize Pool based on a first come first served basis."
            values={{
              whiteSpan: chunk => <span style={{ color: 'var(--whiteColor)' }}>{chunk}</span>
            }}
          />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id='hiw-5-content-3' defaultMessage="Another interesting point to note is that the prize pool will be split equally among all goal achievers, with a max cap of <whiteSpan>369 slots</whiteSpan> available. Meaning if there are only 100 total hunters to achieve this goal, the prize pool will be split among the 100 hunters."
            values={{
              whiteSpan: chunk => <span style={{ color: 'var(--whiteColor)' }}>{chunk}</span>
            }}
          />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id='hiw-5-content-4' defaultMessage="And for example, hypothetically, if there were only 10 hunters who achieved this goal, then each hunter would receive a whopping <whiteSpan>36,900 USD</whiteSpan>; a tenth of the prize pool! You won't want to miss this out, so be sure to mint a copy of each of our T-BOXes to participate."
            values={{
              whiteSpan: chunk => <span style={{ color: 'var(--whiteColor)' }}>{chunk}</span>
            }}
          />
        </StyledText>
      </ContentWrapper>
    </>
  )
}

export default AnnualCommonPrizePool