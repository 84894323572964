import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StyledText } from 'components/GlobalStyle'
import { Image } from '@mantine/core'
import ContentWrapper from '../ContentWrapper'

const AcquiringTokens: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <ContentWrapper title={intl.formatMessage({ id: 'gs-1-title', defaultMessage: 'Acquiring USDT or DAI Tokens' })}>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="gs-1-subtitle-1"
            defaultMessage="The native tokens of BNB Smart Chain (BSC) are BNB which is used to perform most function on BSC, paying gas fees, staking, or in this case; purchasing a MetaTreasure NFT. "
          />
        </StyledText>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="gs-1-subtitle-2"
            defaultMessage="There are a couple of ways you can go about acquiring BNB Tokens, but here’s the method we recommend"
          />:
        </StyledText>

        <ol className='grey-marker' type='1'>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-1-list-1' defaultMessage='Visit Binance.com and login to your binance account' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-1-list-2' defaultMessage='Register your account and complete your Know-Your-Customer (KYC) verification.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-1-list-3' defaultMessage='Under the Trades Tab look for Fiat and first purchase some USDT with your preferred currency using either credit cards/deposits.' />
            </StyledText>
            <Image src='/images/helpdesk/getting-started/gs-1-pic1.jpg' alt='Purchase USDT' mt={40} mb={30} maw={220} w='100%' m='auto' />
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-1-list-4' defaultMessage='Once completed, head over to the wallet tab and select withdraw.' />
            </StyledText>
            <Image src='/images/helpdesk/getting-started/gs-1-pic2.jpg' alt='Withdraw USDT' mt={40} mb={30} maw={220} w='100%' m='auto' />
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-1-list-5' defaultMessage='Copy your MetaMask address and paste it in the address bar.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-1-list-6' defaultMessage='<whiteSpan>IMPORTANT</whiteSpan><redSpan>*</redSpan> - under “Network” select BNB Smart Chain (BEP20).'
                values={{
                  whiteSpan: chunk => <span style={{ color: 'var(--whiteColor)' }}>{chunk}</span>,
                  redSpan: chunk => <span style={{ color: 'red' }}>{chunk}</span>
                }}
              />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-1-list-7' defaultMessage='Key in your intended withdrawal amount and hit “Withdraw”.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-1-list-8' defaultMessage='That’s it you should receive your BNB in your MetaMask wallet.' />
            </StyledText>
          </li>
        </ol>
      </ContentWrapper>
    </>
  )
}

export default AcquiringTokens