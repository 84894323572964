import { AddressZero } from '@ethersproject/constants'

export const multichainTokens = {
  bnb: {
    symbol: 'BNB',
    address: {
      1: '',
      5: '',
      56: AddressZero,
      97: AddressZero,
    },
    decimals: {
      1: 18,
      5: 18,
      56: 18,
      97: 18,
    }
  },
  eth: {
    symbol: 'ETH',
    address: {
      1: AddressZero,
      5: AddressZero,
      56: '',
      97: '',
    },
    decimals: {
      1: 18,
      5: 18,
      56: 18,
      97: 18,
    }
  },
  busd: {
    symbol: 'BUSD',
    address: {
      1: '',
      5: '',
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      97: '',
    },
    decimals: {
      1: 18,
      5: 18,
      56: 18,
      97: 18,
    }
  },
  usdt: {
    symbol: 'USDT',
    address: {
      1: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      5: '',
      56: '0x55d398326f99059fF775485246999027B3197955',
      97: '',
    },
    decimals: {
      1: 6,
      5: 6,
      56: 18,
      97: 18,
    }
  },
  dai: {
    symbol: 'DAI',
    address: {
      1: '0x6b175474e89094c44da98b954eedeac495271d0f',
      5: '',
      56: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
      97: '',
    },
    decimals: {
      1: 6,
      5: 6,
      56: 18,
      97: 18,
    }
  },

  ycoin: {
    symbol: 'YCOIN',
    address: {
      1: '',
      5: '',
      56: '',
      97: '0xe5809979FaA818fFB07F2567935222FA3f8bC72d'
    },
    decimals: {
      1: 18,
      5: 18,
      56: 18,
      97: 18,
    }
  },
  xcoin: {
    symbol: 'XCOIN',
    address: {
      1: '',
      5: '',
      56: '',
      97: '0x769d7029833a9Ec81BDb28cE9bD6B0C9C4B3E96b'
    },
    decimals: {
      1: 18,
      5: 18,
      56: 18,
      97: 18,
    }
  },
  tbc: {
    symbol: 'TBC',
    address: {
      1: '',
      5: '',
      56: '0xdA0F9F1204395C2481070c1d4DC1f996915527f2',
      97: ''
    },
    decimals: {
      1: 18,
      5: 18,
      56: 18,
      97: 18,
    }
  }
}