import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Image } from '@mantine/core'
import { StyledText } from 'components/GlobalStyle'
import ContentWrapper from '../ContentWrapper'

const HowToMint: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <ContentWrapper title={intl.formatMessage({ id: 'hiw-2-title', defaultMessage: 'How to Mint T-Box?' })}>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="hiw-2-subtitle-1"
            defaultMessage="Ready to get started on your Treasure hunting journey?"
          />
        </StyledText>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="hiw-2-subtitle-2"
            defaultMessage="Visit us at T-Box.live and click the connect button at the top right corner of the website. You will be prompted to connect your crypto wallet to proceed."
          />
        </StyledText>

        <Image src='/images/helpdesk/how-it-works/hiw-2-pic1.png' alt='Metatreasure Connect Wallet' mt={40} mb={30} />

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={16}>
          <FormattedMessage id='hiw-2-content-1'
            defaultMessage="For veteran blockchain users, you may be already familiar with MetaMask, if not visit <redirect>here</redirect> are some simple steps to setting up your very own MetaMask wallet."
            values={{
              br: <br />,
              redirect: chunk =>
              <a style={{ color: 'var(--whiteColor)' }}
                href='https://x.com/meta_treasure/status/1791412466242703582'
                target='_blank' rel='noreferrer noopener'
              >
                {chunk}
              </a>
            }}
          />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={16}>
          <FormattedMessage id='hiw-2-content-1a'
            defaultMessage="MetaTreasure also offers connectivity via PulseWallet which offers top-notched security and privacy to their users.{br}{br}Once connected, you will be able to head over to the minting page and select any of the 4 newly added T-Boxes, each with their different rewards, and click! Keep track of your minted NFTs in our inventory system as well as check out the live counter on each T-Box to see the minted progress."
            values={{
              br: <br />,
            }}
          />
        </StyledText>

        <Image src='/images/helpdesk/how-it-works/hiw-2-pic2.png' alt='Metatreasure Minted Counter' mt={40} mb={30} />

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={16}>
          <FormattedMessage id='hiw-2-content-2' defaultMessage="There are plenty of rewards once our T-Boxes are fully minted!{br}{br}MetaTreasure is built on the BNB smart chain, to mint T-Boxes users are required to use either USDT or DAI, if you require a guide for acquiring said tokens, simply refer to this quick <redirect>guide</redirect>. Also remember to use a referral link (if you have one) before minting as you may be able to receive potential airdrops in the future!"
            values={{
              br: <br />,
              redirect: chunk =>
              <a style={{ color: 'var(--whiteColor)' }}
                href='https://x.com/meta_treasure/status/1811720679139737859'
                target='_blank' rel='noreferrer noopener'
              >
                {chunk}
              </a>
            }}
          />
        </StyledText>
      </ContentWrapper>
    </>
  )
}

export default HowToMint