import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StyledText } from 'components/GlobalStyle'
import ContentWrapper from '../ContentWrapper'

const AddChainNetwork: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <ContentWrapper title={intl.formatMessage({ id: 'gs-2-title', defaultMessage: 'Adding BNB Chain & PulseChain Network' })}>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="gs-2-subtitle-1"
            defaultMessage="After setting up your own MetaMask or PulseWallet, let us talk about adding the BNB Chain and PulseChain into your wallet to connect to BNB/Pulse-Based assets and dApps."
          />
        </StyledText>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="gs-2-subtitle-2"
            defaultMessage="For PulseWallet Users, good news! PulseWallet has already integrated most EVM(Ethereum Virtual Machine)-Chains directly into their Wallet. This means you will just need to select your preferred network and start your crypto journey."
          />
        </StyledText>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="gs-2-subtitle-3"
            defaultMessage="To add the Binance Smart Chain (BNB Chain) or PulseChain to your MetaMask wallet, follow these steps"
          />:
        </StyledText>

        <ol className='grey-marker' type='1'>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-2-list-1' defaultMessage='Open MetaMask' />
            </StyledText>
            <ul style={{ listStyleType: 'circle' }}>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-2-list-1-1' defaultMessage="Open your MetaMask wallet. If you don't have MetaMask installed, you can download it from the <redirect>official MetaMask website</redirect>."
                    values={{
                      redirect: chunk =>
                      <a style={{ color: 'var(--whiteColor)' }}
                        href='https://metamask.io/'
                        target='_blank' rel='noreferrer noopener'
                      >
                        {chunk}
                      </a>
                    }}
                  />
                </StyledText>
              </li>
            </ul>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-2-list-2' defaultMessage='Open Network Settings' />
            </StyledText>
            <ul style={{ listStyleType: 'circle' }}>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-2-list-2-1' defaultMessage='Click on the network selection dropdown MetaMask interface (it usually says "Ethereum Mainnet" by default).' />
                </StyledText>
              </li>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-2-list-2-2' defaultMessage='Click on "Add Network" at the bottom of the dropdown.' />
                </StyledText>
              </li>
            </ul>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-2-list-3' defaultMessage='Add Network Details' />
            </StyledText>
            <ul style={{ listStyleType: 'circle' }}>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-2-list-3-1' defaultMessage="A new tab or window will open where you can manually enter network details. Fill in the fields with the following information" />:
                </StyledText>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='#338F4F' ml={20} mt={20} lts={0.8}>
                  <FormattedMessage id='gs-2-list-3-1-a' defaultMessage="Network Name: BNB Smart Chain{br}New RPC URL: https://bsc-dataseed.binance.org/{br}Chain ID: 56{br}Currency Symbol: BNB{br}Block Explorer URL: https://bscscan.com{br}{br}Network Name: PulseChain{br}New RPC URL: https://rpc.pulsechain.com{br}Chain ID: 369{br}Currency Symbol: PLS{br}Block Explorer URL: https://scan.pulsechain.com"
                    values={{
                      br: <br />,
                    }}
                  />
                </StyledText>
              </li>
            </ul>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-2-list-4' defaultMessage='Save Network' />
            </StyledText>
            <ul style={{ listStyleType: 'circle' }}>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-2-list-4-1' defaultMessage='After entering the details, click "Save".' />
                </StyledText>
              </li>
            </ul>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-2-list-5' defaultMessage='Switch to BNB Chain/PulseChain' />
            </StyledText>
            <ul style={{ listStyleType: 'circle' }}>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-2-list-5-1' defaultMessage='Once saved, both the BNB Smart Chain Network and PulseChain Network will be available in your network dropdown. Select any to switch between the BNB Chain and PulseChain.' />
                </StyledText>
              </li>
            </ul>
          </li>
        </ol>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id='gs-2-content-1' defaultMessage="After completing these steps, your MetaMask wallet and PulseWallet will both be configured to use the Binance Smart Chain and PulseChain, allowing you to interact with BNB, BSC, and PulseChain-based assets and dApps."
          />
        </StyledText>
      </ContentWrapper>
    </>
  )
}

export default AddChainNetwork