import { Flex } from '@mantine/core'
import { StyledText } from 'components/GlobalStyle'
import React from 'react'

const ContentWrapper: React.FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => {
  return (
    <>
      <Flex direction='column'>
        <Flex bg='var(--btnMainColor)' p='16px 30px' style={{ borderRadius: '10px' }}>
          <StyledText ff='var(--fontFamilyB)' fw={700} size='clamp(20px, 4vw, 25px)'>
            {title}
          </StyledText>
        </Flex>

        <div style={{ padding: '0 clamp(10px, 4vw, 22px)' }}>
          {children}
        </div>
      </Flex>
    </>
  )
}

export default ContentWrapper