import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'
import { Navbar } from '@nextui-org/react'
import { Flex } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { ConnectWallet } from 'components/ConnectWallet'
import LangSelector from 'components/LangSelector'
import { StyledText } from 'components/GlobalStyle'

const Menu: React.FC = () => {

  const location = useLocation()
  const { pathname } = location
  const splitLocation = pathname.split("/")

  // condition to trigger mobile show / hide for login and language selector
  const matchesMobile = useMediaQuery('(max-width: 460px)')

  const navbarToggleRef = useRef(null)

  const closeMenu = () => {
    navbarToggleRef.current?.click()
  }

  return (
    <>
      <Navbar maxWidth='fluid' className='m-navbar' disableShadow >
        <Navbar.Brand>
          <a href='#!'>
            <Flex align='center' gap='5px'>
              <img src='/images/logo.png' alt='logo' style={{ maxHeight: '18px' }} />
              <StyledText size='clamp(15px, 2vw, 22px)' ff='var(--fontFamilySB)' fw={600}>T-BOX.LIVE</StyledText>
            </Flex>
          </a>
        </Navbar.Brand>
        <Navbar.Content gap='2rem' hideIn='sm' css={{ marginLeft: '36px', marginRight: 'auto' }}>
          <Navbar.Link key='gameplay' href='/gameplay' className={`${splitLocation[1] === 'gameplay' ? 'active' : ''}`}>
            <FormattedMessage id="label-gameplay" defaultMessage="Gameplay" />
          </Navbar.Link>
          <Navbar.Link key='counter' href='/counter' className={`${splitLocation[1] === 'counter' ? 'active' : ''}`}>
            <FormattedMessage id="label-counter" defaultMessage="Counter" />
          </Navbar.Link>
          <Navbar.Link key='mint' href='/mint' className={`${splitLocation[1] === 'mint' ? 'active' : ''}`}>
            <FormattedMessage id="label-mint" defaultMessage="Mint" />
          </Navbar.Link>
          <Navbar.Link key='inventory' href='/inventory' className={`${splitLocation[1] === 'inventory' ? 'active' : ''}`}>
            <FormattedMessage id="label-inventory" defaultMessage="Inventory" />
          </Navbar.Link>
          <Navbar.Link key='referral' href='/referral' className={`${splitLocation[1] === 'referral' ? 'active' : ''}`}>
            <FormattedMessage id="referral" defaultMessage="Referral" />
          </Navbar.Link>
          <Navbar.Link key='helpdesk' href='/helpdesk' className={`${splitLocation[1] === 'helpdesk' ? 'active' : ''}`}>
            <FormattedMessage id="helpdesk" defaultMessage="Helpdesk" />
          </Navbar.Link>
        </Navbar.Content>
        <Navbar.Content gap='10px'>
          <ConnectWallet />
          {!matchesMobile && <LangSelector />}
          <Navbar.Toggle showIn='sm' aria-label='toggle navigation' ref={navbarToggleRef}  />
        </Navbar.Content>
        <Navbar.Collapse>
          <Navbar.CollapseItem key='counter'>
            <Link to='/gameplay' className={`${splitLocation[1] === 'gameplay' ? 'active' : ''}`} onClick={() => closeMenu()}>
              <FormattedMessage id="label-gameplay" defaultMessage="Gameplay" />
            </Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem key='counter'>
            <Link to='/counter' className={`${splitLocation[1] === 'counter' ? 'active' : ''}`} onClick={() => closeMenu()}>
              <FormattedMessage id="label-counter" defaultMessage="Counter" />
            </Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem key='mint'>
            <Link to='/mint' className={`${splitLocation[1] === 'mint' ? 'active' : ''}`} onClick={() => closeMenu()}>
              <FormattedMessage id="label-mint" defaultMessage="Mint" />
            </Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem key='inventory'>
            <Link to='/inventory' className={`${splitLocation[1] === 'inventory' ? 'active' : ''}`} onClick={() => closeMenu()}>
              <FormattedMessage id="label-inventory" defaultMessage="Inventory" />
            </Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem key='referral'>
            <Link to='/referral' className={`${splitLocation[1] === 'referral' ? 'active' : ''}`} onClick={() => closeMenu()}>
              <FormattedMessage id="referral" defaultMessage="Referral" />
            </Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem key='helpdesk'>
            <Link to='/helpdesk' className={`${splitLocation[1] === 'helpdesk' ? 'active' : ''}`} onClick={() => closeMenu()}>
              <FormattedMessage id="helpdesk" defaultMessage="Helpdesk" />
            </Link>
          </Navbar.CollapseItem>
          {matchesMobile &&
            <Navbar.CollapseItem>
              <LangSelector style={{ background: 'var(--btnMainColor)' }} />
            </Navbar.CollapseItem>
          }
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default Menu