import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StyledText } from 'components/GlobalStyle'
import { Flex, Image } from '@mantine/core'
import { FaCheckSquare, FaTimes } from 'react-icons/fa'
import ContentWrapper from '../ContentWrapper'

const CreateWallet: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <ContentWrapper title={intl.formatMessage({ id: 'gs-4-title', defaultMessage: 'Creating a Crypto Wallet' })}>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="gs-4-subtitle-1"
            defaultMessage="To get started on MetaTreasure’s T-Box, you'll first need to set up a crypto wallet. Wallets are available both on desktop computers and on smartphone devices. You'll need to choose the wallet that fits your needs best."
          />
        </StyledText>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="gs-4-subtitle-2"
            defaultMessage="When you're setting up a wallet, be sure to"
          />:
        </StyledText>

        <ul style={{ listStyle: 'none' }}>
          <li>
            <Flex align='center' gap={10}>
              <FaCheckSquare fill='green' size={18} />
              <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)'>
                <FormattedMessage id='gs-4-list-1' defaultMessage='Download and install only the latest version from an official source.' />
              </StyledText>
            </Flex>
          </li>
          <li>
            <Flex align='center' gap={10}>
              <FaCheckSquare fill='green' size={18} />
              <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)'>
                <FormattedMessage id='gs-4-list-2' defaultMessage='Follow the setup guide carefully.' />
              </StyledText>
            </Flex>
          </li>
          <li>
            <Flex align='center' gap={10}>
              <FaCheckSquare fill='green' size={18} />
              <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)'>
                <FormattedMessage id='gs-4-list-3' defaultMessage='Safely back up your recovery phrases.' />
              </StyledText>
            </Flex>
          </li>
          <li>
            <Flex align='center' gap={10}>
              <FaTimes fill='#F92F60' size={19} />
              <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)'>
                <FormattedMessage id='gs-4-list-4' defaultMessage='NEVER share your recovery phrases with anyone, under any circumstances.' />
              </StyledText>
            </Flex>
          </li>
          <li>
            <Flex align='center' gap={10}>
              <FaTimes fill='#F92F60' size={19} />
              <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)'>
                <FormattedMessage id='gs-4-list-5' defaultMessage='NEVER input your recovery phrase to a website or app, other than your wallet app.' />
              </StyledText>
            </Flex>
          </li>
        </ul>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' mt={32} mb={16}>
          <FormattedMessage id='gs-4-title-1' defaultMessage='Smartphone/Mobile or Desktop Wallet?' />
        </StyledText>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={16}>
          <FormattedMessage id='gs-4-content-1' defaultMessage="Mobile device wallets and desktop-based wallets have different strengths and weaknesses. Consider which fits your needs better to help decide which type of wallet to use."
          />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={16}>
          <FormattedMessage id='gs-4-content-2' defaultMessage="Smartphone/Mobile wallets allow you to access your crypto almost anywhere. Most wallets are available on both Android and iOS devices."
          />
        </StyledText>

        <Image src='/images/helpdesk/getting-started/gs-4-pic1.png' alt='Wallet in Mobile and Desktop Difference' mt={40} mb={30} />

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={16}>
          <FormattedMessage id='gs-4-content-3'
            defaultMessage="While there are plenty of crypto wallets in the market these days, MetaTreasure recommends the usage of MetaMask."
          />
        </StyledText>

        <Image src='/images/helpdesk/getting-started/gs-4-pic2.png' alt='Metamask' mt={40} mb={30} />

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={16}>
          <FormattedMessage id='gs-4-content-4'
            defaultMessage="MetaMask is a very popular browser-based wallet plugin that supports ERC20 (Ethereum network), BEP2 & BEP20 (Binance Beacon Chain and BNB Smart Chain networks), and PRC20 (PulseChain Network)."
          />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' mt={32}>
          <FormattedMessage id='gs-4-title-2' defaultMessage='Pros' />:
        </StyledText>
        <ul className='grey-marker' style={{ listStyleType: 'disc' }}>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-4-list-6' defaultMessage='Open source for audibility' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-4-list-7' defaultMessage='WEB3 capable on BscScan' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-4-list-8' defaultMessage='A strong history of security and reliability' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-4-list-9' defaultMessage='A large number of how-to guides and resources online' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-4-list-10' defaultMessage='Many tools and custom settings' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-4-list-11' defaultMessage='Supports a vast number of languages' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-4-list-12' defaultMessage='Is also available on mobile devices' />
            </StyledText>
          </li>
        </ul>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' mt={32}>
          <FormattedMessage id='gs-4-title-3' defaultMessage='Cons' />:
        </StyledText>
        <ul className='grey-marker' style={{ listStyleType: 'disc' }}>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-4-list-13' defaultMessage='Longer setup time than other options' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-4-list-14' defaultMessage='Additional steps needed to use BNB Smart Chain and MetaTreasure' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-4-list-15' defaultMessage='Extra information and settings may confuse beginners' />
            </StyledText>
          </li>
        </ul>

        <a className='hover-underline' href='https://metamask.io/download/' target='_blank' rel='noopener noreferrer'
          style={{ marginTop: '16px', display: 'block', marginBottom: '48px' }}
        >
          <StyledText fw={600} size='clamp(16px, 4vw, 17px)' >
            <FormattedMessage id='label-download-metamask' defaultMessage='Download MetaMask' />
          </StyledText>
        </a>
      </ContentWrapper>

      <ContentWrapper title={intl.formatMessage({ id: 'gs-4a-title', defaultMessage: 'MetaMask Setup Guide' })}>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' mt={32} mb={16}>
          <FormattedMessage id='label-setup-guide-android-ios' defaultMessage='Setup Guide (Android/iOS)' />
        </StyledText>
        <ol className='grey-marker' type='1'>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-4-list-16' defaultMessage='Download MetaMask via Google Play Store or AppStore' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-4-list-17' defaultMessage='Hit the sign-up button and key in your preferred details' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='mnemonic-warning'
                defaultMessage='<whiteSpan>IMPORTANT</whiteSpan><redSpan>*</redSpan> - Take note of your Mnemonic Phrase as losing it will mean that you will not be able to recover your account if you forget your password.'
                values={{
                  whiteSpan: chunk => <span style={{ color: 'var(--whiteColor)' }}>{chunk}</span>,
                  redSpan: chunk => <span style={{ color: 'red' }}>{chunk}</span>
                }}
              />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-4-list-19' defaultMessage='And that’s it you’re done' />
            </StyledText>
          </li>
        </ol>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' mt={32} mb={16}>
          <FormattedMessage id='label-setup-guide-chrome' defaultMessage='Setup Guide (Chrome)' />
        </StyledText>
        <ol className='grey-marker' type='1'>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-4-list-20' defaultMessage='Visit <redirect>MetaMask</redirect> and hit the Install MetaMask for Chrome'
                values={{
                  redirect: chunk =>
                    <a className='hover-underline' style={{ color: 'var(--whiteColor)' }}
                      href='https://metamask.io/download/'
                      target='_blank' rel='noreferrer noopener'
                    >
                      {chunk}
                    </a>
                }}
              />
            </StyledText>
            <Image src='/images/helpdesk/getting-started/gs-4-pic3.png' alt='Install MetaMask for Chrome' mt={40} mb={30} maw={400} w='100%' m='auto' />
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-4-list-21' defaultMessage='You will be brought to the Chrome web store, and click on the add MetaMask extension button.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-4-list-22' defaultMessage='Hit the sign-up button on your MetaMask extension and key in your preferred details.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='mnemonic-warning'
                defaultMessage='<whiteSpan>IMPORTANT</whiteSpan><redSpan>*</redSpan> - Take note of your Mnemonic Phrase as losing it will mean that you will not be able to recover your account if you forget your password.'
                values={{
                  whiteSpan: chunk => <span style={{ color: 'var(--whiteColor)' }}>{chunk}</span>,
                  redSpan: chunk => <span style={{ color: 'red' }}>{chunk}</span>
                }}
              />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='all-set-msg' defaultMessage='And that’s it, you’re all set.' />
            </StyledText>
          </li>
        </ol>
      </ContentWrapper>
    </>
  )
}

export default CreateWallet