import { Flex } from '@mantine/core'
import { StyledText } from 'components/GlobalStyle'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

const FaqContent: React.FC = () => {
  const intl = useIntl()

  const faqList = [
    {
      id: 1,
      question: intl.formatMessage({ id: "faq-content-q1", defaultMessage: "What is MetaTreasure, and how does it work?" }),
      answer: intl.formatMessage({
        id: "faq-content-a1",
        defaultMessage: "MetaTreasure is a unique NFT and DeFi project on the BSC Network & PulseChain that combines innovative game theory, deflationary tokenomics, and community-driven growth. It offers various opportunities through T-BOX NFTs, Node & B-Farming pools, and more."
      }),
      hasTitleDividerAbove: false,
      titleDividerLabel: ''
    },
    {
      id: 2,
      question: intl.formatMessage({ id: "faq-content-q2", defaultMessage: "How do I get started with MetaTreasure?" }),
      answer: intl.formatMessage({
        id: "faq-content-a2",
        defaultMessage: "To get started, you can purchase T-BOX NFTs through our platform, join Node & B-Farming pools, and participate in community events. Detailed instructions are available on our official website."
      }),
      hasTitleDividerAbove: false,
      titleDividerLabel: ''
    },
    {
      id: 3,
      question: intl.formatMessage({ id: "faq-content-q3", defaultMessage: "What are T-BOX NFTs, and what benefits do they offer?" }),
      answer: intl.formatMessage({
        id: "faq-content-a3",
        defaultMessage: "T-BOX NFTs are special digital assets on the BNB Chain that come with rewards such as merchant discounts, airdrops, and chances to win luxury prizes. They play a key role in MetaTreasure’s ecosystem."
      }),
      hasTitleDividerAbove: false,
      titleDividerLabel: ''
    },
    {
      id: 4,
      question: intl.formatMessage({ id: "faq-content-q4", defaultMessage: "When will the next round of TreasureBox Minting begin?" }),
      answer: intl.formatMessage({
        id: "faq-content-a4",
        defaultMessage: "There will be a 30-minute break between rounds before the TreasureBoxes are unboxed. After the unboxing, the following round will begin immediately."
      }),
      hasTitleDividerAbove: false,
      titleDividerLabel: ''
    },
    {
      id: 5,
      question: intl.formatMessage({ id: "faq-content-q5", defaultMessage: "When will the TreasureBoxes be unboxed?" }),
      answer: intl.formatMessage({
        id: "faq-content-a5",
        defaultMessage: "All TreasureBoxes will be unboxed 30 minutes after the round ends. The TreasureBoxes will ONLY be unboxed after the round is completed."
      }),
      hasTitleDividerAbove: false,
      titleDividerLabel: ''
    },
    {
      id: 6,
      question: intl.formatMessage({ id: "faq-content-q6", defaultMessage: "What is the $MTT token, and how can I earn it?" }),
      answer: intl.formatMessage({
        id: "faq-content-a6",
        defaultMessage: "$MTT is the native token of MetaTreasure. You can earn it by participating in Node & B-Farming pools, holding T-BOX NFTs, and engaging in various activities within the MetaTreasure ecosystem."
      }),
      hasTitleDividerAbove: true,
      titleDividerLabel: `${intl.formatMessage({ id: 'faq-content-divider-1', defaultMessage: 'Tokenomics & Rewards' })}:`
    },
    {
      id: 7,
      question: intl.formatMessage({ id: "faq-content-q7", defaultMessage: "How does MetaTreasure’s deflationary tokenomics work?" }),
      answer: intl.formatMessage({
        id: "faq-content-a7",
        defaultMessage: "MetaTreasure uses a buy & burn mechanism, funded by the gains from farming pools, to reduce the total supply of $MTT over time. This deflationary model is designed to increase the token’s value."
      }),
      hasTitleDividerAbove: false,
      titleDividerLabel: ''
    },
    {
      id: 8,
      question: intl.formatMessage({ id: "faq-content-q8", defaultMessage: "What rewards can I expect from MetaTreasure’s farming pools?" }),
      answer: intl.formatMessage({
        id: "faq-content-a8",
        defaultMessage: "Farming pools offer attractive APRs and the opportunity to earn $MTT and other rewards. The rewards can also be compounded into additional farming opportunities for greater returns."
      }),
      hasTitleDividerAbove: false,
      titleDividerLabel: ''
    },
    {
      id: 9,
      question: intl.formatMessage({ id: "faq-content-q9", defaultMessage: "Is MetaTreasure safe and secure to use?" }),
      answer: intl.formatMessage({
        id: "faq-content-a9",
        defaultMessage: "MetaTreasure operates on the secure and scalable PulseChain network, employing industry-standard security measures. However, as with all crypto investments, it’s important to do your own research."
      }),
      hasTitleDividerAbove: true,
      titleDividerLabel: `${intl.formatMessage({ id: 'faq-content-divider-2', defaultMessage: 'Security & Trust' })}:`
    },
    {
      id: 10,
      question: intl.formatMessage({ id: "faq-content-q10", defaultMessage: "How does MetaTreasure ensure transparency and trust?" }),
      answer: intl.formatMessage({
        id: "faq-content-a10",
        defaultMessage: "MetaTreasure maintains transparency by regularly updating the community, providing clear information on tokenomics and project developments, and adhering to decentralized principles."
      }),
      hasTitleDividerAbove: false,
      titleDividerLabel: ''
    },
    {
      id: 11,
      question: intl.formatMessage({ id: "faq-content-q11", defaultMessage: "Are NFTs Transferable?" }),
      answer: intl.formatMessage({
        id: "faq-content-a11",
        defaultMessage: "Absolutely! All MetaTreasure NFTs are transferable between wallet addresses, you will be able to resell your NFT with the rewards unclaimed at your preferred sales price." +
        "{br}{br}<styleSpan>Disclaimer</styleSpan>{br}{br}MetaTreasure will not be responsible for any scam between sales of NFTs. It is your responsibility to make proper verification before proceeding with your purchase."
      }, { br: <br />, styleSpan: chunk => <span style={{ color: 'white' }}>{chunk}</span> }),
      hasTitleDividerAbove: false,
      titleDividerLabel: '',
    },
    {
      id: 12,
      question: intl.formatMessage({ id: "faq-content-q12", defaultMessage: "How can I stay updated on MetaTreasure’s progress and news?" }),
      answer: intl.formatMessage({
        id: "faq-content-a12",
        defaultMessage: "You can stay updated by following MetaTreasure on official social media channels like Twitter, Telegram, and Instagram. Regular updates and announcements are also posted on our website."
      }),
      hasTitleDividerAbove: true,
      titleDividerLabel: `${intl.formatMessage({ id: 'faq-content-divider-3', defaultMessage: 'Community & Participation' })}:`
    },
    {
      id: 13,
      question: intl.formatMessage({ id: "faq-content-q13", defaultMessage: "How can I participate in MetaTreasure’s community and events?" }),
      answer: intl.formatMessage({
        id: "faq-content-a13",
        defaultMessage: "Joining our community on social media, participating in events, and engaging with other users are great ways to get involved. MetaTreasure frequently hosts events and offers opportunities for community members to contribute and earn rewards."
      }),
      hasTitleDividerAbove: false,
      titleDividerLabel: ''
    },
  ]

  return (
    <>
      <Flex direction='column'>
        {/* <StyledText fw={600} size='clamp(20px, 4vw, 25px)'>
          <FormattedMessage id="faq-content-title" defaultMessage="MetaTreasure - HUNT FUN EARN" />
        </StyledText> */}

        <Flex bg='var(--btnMainColor)' p='16px 30px' style={{ borderRadius: '10px' }}>
          <StyledText ff='var(--fontFamilyB)' fw={700} size='clamp(20px, 4vw, 25px)'>
            <FormattedMessage id="label-metaTreasure-faq" defaultMessage="MetaTreasure FAQ" />
          </StyledText>
        </Flex>

        <div style={{ padding: '0 clamp(10px, 4vw, 22px)' }}>
          <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
            <FormattedMessage id="faq-content-subtitle-1"
              defaultMessage="Here are some commonly asked questions and answers to help simplify your MetaTreasure experience, if you have any further questions please contact us on any of our social media channels and we will get back to you soonest."
            />
          </StyledText>

          <ol className='content-list' type='1' style={{ listStyleType: 'decimal', listStylePosition: 'inside' }}>
            {faqList.map(item =>
            <>
              {item.hasTitleDividerAbove &&
              <StyledText fw={600} size='clamp(16px, 4vw, 17px)' mt={32} mb={16}>
                {item.titleDividerLabel}
              </StyledText>
              }
              <li key={item.id}>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' display='inline' ml={20}>
                  {item.question}
                </StyledText>

                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={16}>
                  {item.answer}
                </StyledText>
              </li>
            </>
            )}
          </ol>
        </div>
      </Flex>
    </>
  )
}

export default FaqContent