import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StyledText } from 'components/GlobalStyle'
import ContentWrapper from '../ContentWrapper'

const NftActivationCard: React.FC = () => {
  const intl = useIntl()

  const cards = [
    { id: 1, value: 58 },
    { id: 2, value: 48 },
    { id: 3, value: 38 },
    { id: 4, value: 28 },
  ]

  return (
    <>
      <ContentWrapper title={intl.formatMessage({ id: 'hiw-7-title', defaultMessage: 'MetaTreasure NFT Activation Card' })}>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id='hiw-7-content-1' defaultMessage="MetaTreasure will be launching its very own token with a goal of helping users generate additional wealth and growth for their investments. To celebrate, MetaTreasure will be hosting a once in a lifetime special $MTT airdrop that will give all our T-Box holders a chance at getting a headstart in their investment."
          />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id='hiw-7-content-2'
            defaultMessage="To entitle for our free $MTT airdrop worth 0.8-1.5x of your minted T-Box in USD Value, users will have to mint a compatible NFT Activation Card to pair with their respective T-Box to activate the entitlement. Each Activation card will be tied to the different category of T-Boxes and you’ll require both to be in your wallet for this airdrop benefit."
          />
        </StyledText>

        <div style={{ marginTop: '32px' }}>
          {cards.map(item =>
          <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)'>
            <FormattedMessage id='label-nft-card' defaultMessage="NFT Card" /> {item.id} : ${item.value}
          </StyledText>)}
        </div>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id='hiw-7-content-3' defaultMessage="By owning the set of T-Box + NFT Activation Card in a single wallet, you will then qualify to receive free $MTT airdrops overtime & receive complimentary gifts that worths $118!" />
        </StyledText>
      </ContentWrapper>
    </>
  )
}

export default NftActivationCard