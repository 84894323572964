import { Burger, Flex } from '@mantine/core'
import { Page, StyledText } from 'components/GlobalStyle'
import React, { ReactNode, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDisclosure } from '@mantine/hooks'
import { useMediaQuery } from 'react-responsive'
import FaqContent from './Content/FaqContent'
import MetatresureIntroduction from './Content/HowItWorksContent/MetatreasureIntroduction'
import HowToMint from './Content/HowItWorksContent/HowToMint'
import HowToClaimRewards from './Content/HowItWorksContent/HowToClaimRewards'
import RoundCommonPrizePool from './Content/HowItWorksContent/RoundCommonPrizePool'
import AnnualCommonPrizePool from './Content/HowItWorksContent/AnnualCommonPrizePool'
import AffiliateProgram from './Content/HowItWorksContent/AffiliateProgram'
import NftActivationCard from './Content/HowItWorksContent/NftActivationCard'
import AcquiringTokens from './Content/GettingStarted/AcquiringTokens'
import AddChainNetwork from './Content/GettingStarted/AddChainNetwork'
import Bridging from './Content/GettingStarted/Bridging'
import CreateWallet from './Content/GettingStarted/CreateWallet'
import ConnectMetatreasure from './Content/GettingStarted/ConnectMetatreasure'
import CreatePulseWallet from './Content/GettingStarted/CreatePulseWallet'

interface HelpdeskDetailProps {
  cmsType: string
}

type HelpdeskSection = {
  id: number;
  label: string;
  content: ReactNode;
}

type HelpdeskDetailContent = {
  faq: HelpdeskSection[];
  gettingStarted: HelpdeskSection[];
  howItWorks: HelpdeskSection[];
}

const HelpdeskDetail: React.FC<HelpdeskDetailProps> = ({ cmsType }) => {
  const intl = useIntl()
  const flexboxWrapped = useMediaQuery({ query: `(max-width: 1321px)` })

  const [mobileMenuOpened, mobileMenuHandlers] = useDisclosure(false)

  const titleObj = {
    'faq': intl.formatMessage({ id: "label-faq", defaultMessage: "Frequently Ask Question" }),
    'gettingStarted': intl.formatMessage({ id: "label-getting started", defaultMessage: "Getting Started" }),
    'howItWorks': intl.formatMessage({ id: "label-how-it-works", defaultMessage: "How It Works" })
  }

  const [selectedTitle, setSelectedTitle] = useState(1)

  const helpdeskDetailContent: HelpdeskDetailContent = {
    faq: [
      {
        id: 1,
        label: intl.formatMessage({ id: 'label-faq-short', defaultMessage: 'FAQ' }),
        content: <FaqContent />
      }
    ],
    gettingStarted: [
      {
        id: 1,
        label: intl.formatMessage({ id: 'gs-1-title', defaultMessage: 'Acquiring USDT or DAI Tokens' }),
        content: <AcquiringTokens />
      },
      {
        id: 2,
        label: intl.formatMessage({ id: 'gs-2-title', defaultMessage: 'Adding BNB Chain & PulseChain Network' }),
        content: <AddChainNetwork />
      },
      {
        id: 3,
        label: intl.formatMessage({ id: 'gs-3-title', defaultMessage: 'Bridging Assets from Ethereum to PulseChain: A Comprehensive Guide' }),
        content: <Bridging />
      },
      {
        id: 4,
        label: intl.formatMessage({ id: 'gs-4-title', defaultMessage: 'Creating a Crypto Wallet' }),
        content: <CreateWallet />
      },
      {
        id: 5,
        label: intl.formatMessage({ id: 'gs-5-title', defaultMessage: 'How Do I Connect To MetaTreasure' }),
        content: <ConnectMetatreasure />
      },
      {
        id: 6,
        label: intl.formatMessage({ id: 'gs-6-title', defaultMessage: 'How to create a PulseWallet' }),
        content: <CreatePulseWallet />
      },
    ],
    howItWorks: [
      {
        id: 1,
        label: intl.formatMessage({ id: 'label-metatreasure-introduction', defaultMessage: 'MetaTreasure Introduction' }),
        content: <MetatresureIntroduction />
      },
      {
        id: 2,
        label: intl.formatMessage({ id: 'hiw-2-title', defaultMessage: 'How to Mint T-Box?' }),
        content: <HowToMint />
      },
      {
        id: 3,
        label: intl.formatMessage({ id: 'hiw-3-title', defaultMessage: 'How to Claim Rewards' }),
        content: <HowToClaimRewards />
      },
      {
        id: 4,
        label: intl.formatMessage({ id: 'hiw-4-title', defaultMessage: 'Round Common Prize Pool' }),
        content: <RoundCommonPrizePool />
      },
      {
        id: 5,
        label: intl.formatMessage({ id: 'hiw-5-title', defaultMessage: 'Annual Common Prize Pool' }),
        content: <AnnualCommonPrizePool />
      },
      {
        id: 6,
        label: intl.formatMessage({ id: 'hiw-6-title', defaultMessage: 'MetaTreasure Affiliate Program' }),
        content: <AffiliateProgram />
      },
      {
        id: 7,
        label: intl.formatMessage({ id: 'hiw-7-title', defaultMessage: 'MetaTreasure NFT Activation Card' }),
        content: <NftActivationCard />
      },
    ]
  }

  const selectedTitleContent = (helpdeskDetailContent[cmsType] as HelpdeskSection[]).find(q => q.id === selectedTitle)

  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0)
  }, [cmsType])

  return (
    <>
      <Page style={{ background: 'var(--blackColor)' }}>
        <section style={{ padding: '60px 1.1rem 100px' }}>
          <Flex maw={1300} m='auto' direction='column'>
            <StyledText fw={600} size='clamp(18px, 4vw, 20px)' color='var(--textGrey)' mt={16} w='100%' maw={625} mr='auto' ml={flexboxWrapped ? 'auto': '0'}>
              <FormattedMessage id="label-helpdesk"
                defaultMessage="Helpdesk"
              />
            </StyledText>

            <StyledText ff='var(--fontFamilyB)' fw={700} size='clamp(30px, 8vw, 65px)'>
              {titleObj[cmsType]}
            </StyledText>

            {flexboxWrapped && <Burger opened={mobileMenuOpened} onClick={mobileMenuHandlers.toggle} color='white' mt={16} />}

            <Flex wrap='wrap' gap={16} mt='3rem'>
              {/* tab select */}
              <Flex className={`helpdesk-menu ${flexboxWrapped && !mobileMenuOpened ? 'hidden' : ''}`} direction='column' maw={420} w='100%'>
                {helpdeskDetailContent[cmsType].map((item, index) =>
                <Flex key={item.id} p='25px 32px' gap={20} align='center' wrap='wrap' maw={350}
                  bg={item.id === selectedTitle ? 'linear-gradient(90deg, rgba(47,48,47,1) 25%, rgba(35,35,35,1) 40%, rgba(0,0,0,1) 85%)' : 'transparent'}
                  style={{ borderRadius: '10px', border: item.id === selectedTitle ? '1px solid var(--textGrey)' : '1px solid transparent', cursor: 'pointer' }}
                  onClick={() => {
                    setSelectedTitle(item.id)
                    if (flexboxWrapped && mobileMenuOpened) mobileMenuHandlers.close()
                  }}
                >
                  <StyledText fw={600} size='clamp(18px, 4vw, 23px)' mih={69}>
                    {item.label}
                  </StyledText>
                </Flex>)}
              </Flex>

              {/* content */}
              <Flex direction='column' maw={850} ml='auto' mr='auto'>
                {selectedTitleContent ? (
                  selectedTitleContent.content
                )
                :
                <></>
                }
              </Flex>
            </Flex>
          </Flex>
        </section>
      </Page>
    </>
  )
}

export default HelpdeskDetail