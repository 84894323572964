import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StyledText } from 'components/GlobalStyle'
import ContentWrapper from '../ContentWrapper'

const AffiliateProgram: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <ContentWrapper title={intl.formatMessage({ id: 'hiw-6-title', defaultMessage: 'MetaTreasure Affiliate Program' })}>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="hiw-6-subtitle-1"
            defaultMessage="Have you ever heard the saying “Teamwork makes the dream work”? We believe in this saying and to help get the spirit of sharing rolling. We would like to Introduce our MetaTreasure Affiliate Program, to help grow the community and share the rewards with everyone!"
          />
        </StyledText>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="hiw-6-subtitle-2"
            defaultMessage="Here is how it works"
          />:
        </StyledText>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="hiw-6-subtitle-3"
            defaultMessage="To not over-complicate things, MetaTreasure offers a very simple tiered referral system. We currently offer a <whiteSpan>triple-tiered reward system</whiteSpan> that allows users to earn rewards whenever a referral mints a new T-Box."
            values={{
              whiteSpan: chunk => <span style={{ color: 'var(--whiteColor)' }}>{chunk}</span>
            }}
          />
        </StyledText>

        <ol className='grey-marker' type='1'>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='hiw-6-list-1' defaultMessage='For every new referral, you will earn 10% of every minted T-Box with your referral.'
                values={{
                  whiteSpan: chunk => <span style={{ color: 'var(--whiteColor)' }}>{chunk}</span>
                }}
              />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='hiw-6-list-2' defaultMessage='If your teammates invite additional new referrals, you will also be rewarded another <whiteSpan>5% for their minted T-Boxes</whiteSpan>.'
                values={{
                  whiteSpan: chunk => <span style={{ color: 'var(--whiteColor)' }}>{chunk}</span>
                }}
              />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='hiw-6-list-3' defaultMessage='If those new invitees further invite other teammates, you will be rewarded an additional <whiteSpan>2.5% for their minted T-Boxes</whiteSpan>.'
                values={{
                  whiteSpan: chunk => <span style={{ color: 'var(--whiteColor)' }}>{chunk}</span>
                }}
              />
            </StyledText>
          </li>
        </ol>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id='hiw-6-content-1' defaultMessage="This will allow users to exponentially grow and earn! Don’t forget to all participants of T-Boxes you’ll also be qualified for our amazing future airdrop rewards! Hurry and share your referral code today."
          />
        </StyledText>
      </ContentWrapper>
    </>
  )
}

export default AffiliateProgram