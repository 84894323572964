import { StyledText } from 'components/GlobalStyle'
import { CHAIN_ID, EXPLORER_URLS } from 'config/constants/networks'
import { multichainTokens } from 'config/constants/tokens'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { getNftAddress } from 'utils/addressHelpers'

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear()
  const { chainId } = useActiveWeb3React()
  const nftContractAddress = getNftAddress(chainId)

  return (
    <div style={{ background: 'linear-gradient(0deg, #1B1B1C 0%, #2F302F 100%)', padding: '80px 20px'}}>
      <StyledText size='clamp(30px, 8vw, 65px)' ff='var(--fontFamilyB)' ta='center'>
        <FormattedMessage id='footer-slogan' defaultMessage='Hunt Fun Earn' />
      </StyledText>
      <StyledText color='#808184' ta='center' mt={10} size='clamp(13.5px, 3vw, 16px)'>
        <FormattedMessage id='label-metatreasure-token-contract' defaultMessage='MetaTreasure Token Contract' />:&nbsp;
        <a href='https://scan.pulsechainfoundation.org/#/address/0x8aB5BAB83E62D647aF74631479885556Ee9f3410' target='_blank' rel='noopener noreferrer'>
          0x8aB5BAB83E62D647aF74631479885556Ee9f3410
        </a>
        <br />
        <FormattedMessage id='label-metatreasure-nft-contract' defaultMessage='MetaTreasure NFT Contract' />:&nbsp;
        <a href={`${EXPLORER_URLS[CHAIN_ID.BSC]}/address/${nftContractAddress}`} target='_blank' rel='noopener noreferrer'>
          {nftContractAddress}
        </a>
        <br />
        <FormattedMessage id='label-tbc-contract' defaultMessage='TBC Contract' />:&nbsp;
        <a href={`${EXPLORER_URLS[CHAIN_ID.BSC]}/address/${multichainTokens.tbc.address[CHAIN_ID.BSC]}`} target='_blank' rel='noopener noreferrer'>
          {multichainTokens.tbc.address[CHAIN_ID.BSC]}
        </a>
      </StyledText>
      <StyledText color='#808184' ta='center' mt={40}>
        <FormattedMessage id='label-copyright' defaultMessage='CopyRight' />
        &nbsp;&copy; {currentYear} {process.env.REACT_APP_PROJECT_NAME}&nbsp;
        <FormattedMessage id='label-rights' defaultMessage='All Rights Reserved' />
      </StyledText>
    </div>
  )
}

export default Footer