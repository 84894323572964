import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StyledText } from 'components/GlobalStyle'
import ContentWrapper from '../ContentWrapper'

const ConnectMetatreasure: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <ContentWrapper title={intl.formatMessage({ id: 'gs-5-title', defaultMessage: 'How Do I Connect To MetaTreasure' })}>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="gs-5-subtitle-1"
            defaultMessage="Great! You have created a secured wallet and gotten your DAI/USDT tokens, now all you have to do is connect your preferred wallet with MetaTreasure and you’re good to go!"
          />
        </StyledText>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="gs-5-subtitle-2"
            defaultMessage="Follow the simple steps below to connect your MetaMask wallet to MetaTreasure."
          />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' mt={32} mb={16}>
          <FormattedMessage id='gs-5-title-1' defaultMessage='Android and iOS Users' />
        </StyledText>

        <ol className='grey-marker' type='1'>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-5-list-1' defaultMessage='Adding Network' />
            </StyledText>
            <ul style={{ listStyleType: 'circle' }}>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-5-list-1-1' defaultMessage="Click on the network dropdown menu on MetaMask and select “Custom RPC”"
                    values={{
                      redirect: chunk =>
                      <a style={{ color: 'var(--whiteColor)' }}
                        href='https://metamask.io/'
                        target='_blank' rel='noreferrer noopener'
                      >
                        {chunk}
                      </a>
                    }}
                  />
                </StyledText>
              </li>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-5-list-1-2' defaultMessage="Enter the BSC network details" />
                </StyledText>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='#338F4F' ml={20} mt={20} lts={0.8}>
                  <FormattedMessage id='gs-5-list-1-2-a' defaultMessage="Network Name: <redSpan>BNB Smart Chain</redSpan>{br}New RPC URL: <redSpan>https://bsc-dataseed.binance.org/</redSpan>{br}Chain ID: <redSpan>56</redSpan>{br}Currency Symbol: <redSpan>BNB</redSpan>{br}Block Explorer URL: <redSpan>https://bscscan.com</redSpan>{br}{br}"
                    values={{
                      br: <br />,
                      redSpan: chunk => <span style={{ color: '#A4225B' }}>{chunk}</span>
                    }}
                  />
                </StyledText>
              </li>
            </ul>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-5-list-2'
                defaultMessage='Once you’ve confirmed entry into the new network, return to the dropdown menu and select “Browser”, this will be your main method to access most dApps with a mobile device.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-5-list-3' defaultMessage='Open a new browser tab and key in https://t-box.live' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-5-list-4' defaultMessage='Your wallet will ask you to connect. Tap Connect to connect to MetaTreasure. ' />
            </StyledText>
          </li>
        </ol>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' mt={32} mb={16}>
          <FormattedMessage id='gs-5-title-2' defaultMessage='For PC Users' />
        </StyledText>
        <ol className='grey-marker' type='1'>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-5-list-5' defaultMessage='Download MetaMask Extension into your PC browser.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-5-list-6' defaultMessage='Log in to your MetaMask Account on your Browsers extension.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-5-list-7' defaultMessage='Open a new browser tab and key in https://t-box.live' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-5-list-8' defaultMessage='On the homepage, there will be a “Connect” button on the top right corner, click the button.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-5-list-9' defaultMessage='A MetaMask pop-up will request your connecting permission.' />
            </StyledText>
          </li>
        </ol>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id='gs-5-content-1' defaultMessage="Once approved, you're good to go" />
        </StyledText>

        <a className='hover-underline' href='https://x.com/compose/articles/edit/1791408134638231552' target='_blank' rel='noopener noreferrer'
          style={{ marginTop: '16px', display: 'block', marginBottom: '48px' }}
        >
          <StyledText fw={600} size='clamp(16px, 4vw, 17px)' >
            <FormattedMessage id='label-metamask-setup-guide' defaultMessage='MetaMask Setup Guide' />
          </StyledText>
        </a>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id='gs-5-content-2' defaultMessage="Time To Win Some Big Rewards With MetaTreasure!" />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id='gs-5-content-3' defaultMessage="MetaTreasure - Where Everyone Is A Winner!" />
        </StyledText>
      </ContentWrapper>
    </>
  )
}

export default ConnectMetatreasure