import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StyledText } from 'components/GlobalStyle'
import ContentWrapper from '../ContentWrapper'

const HowToClaimRewards: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <ContentWrapper title={intl.formatMessage({ id: 'hiw-3-title', defaultMessage: 'How to Claim Rewards' })}>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="hiw-3-subtitle-1"
            defaultMessage="Congratulations! You have unboxed a T-Box with a special reward! How do we determine if a T-Box is entitled to a reward claim? With MetaTreasure any T-Box that is uncommon and above will be entitled to claim a reward!"
          />
        </StyledText>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="hiw-3-subtitle-2"
            defaultMessage="So how do you claim your rewards?"
          />
        </StyledText>

        <ol className='grey-marker' type='1'>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='hiw-3-list-1' defaultMessage='Visit our T-Box site and connect your wallet.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='hiw-3-list-2' defaultMessage='Once connected, head to your inventory page' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='hiw-3-list-3' defaultMessage='There will be a claim button for you to claim your reward if the T-Boxes are entitled to a reward.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='hiw-3-list-4' defaultMessage='Click the claim reward button and you will be required to fill in some personal details including your shipping address for us to ship the reward to you.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='hiw-3-list-5' defaultMessage='We will require up to 2 weeks or more to process the shipping, once ready, a shipping tracker will be provided to you.' />
            </StyledText>
          </li>
        </ol>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id='hiw-3-content-1' defaultMessage="So what’s next after you’ve claimed your reward? Well, the fun doesn’t stop there, keep an eye out for our Annual Common Prize Pool! An amazing first come first serve reward that will keep you on your toes, don’t miss out on the massive 369,000 USD prize pool up for grabs. There is also a Round common prize pool after each round which will select 80 lucky winners at random to win additional rewards!"
          />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id='hiw-3-content-2' defaultMessage="Join our MetaTreasure today, where the fun never ends!" />
        </StyledText>
      </ContentWrapper>
    </>
  )
}

export default HowToClaimRewards