import { CHAIN_ID } from './networks'

export type AddressMap = Partial<Record<CHAIN_ID, string>>

export default {
  multiCall: {
    1: '0x2a80799F88896E16f963284C7583f365CD645A1B',
    5: '0x925452ab590bdE90800a6265b4C6E16983194C25',
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  },
  purchaseCashierContr: {
    1: '',
    5: '',
    56: '0xd4c54fC8D6bD9532ED9E2e5A4911F718941617cf',
    97: '0xa75cae2581f8468f4dd5fE417cd69688B405D954'
  },
  nftContr: {
    1: '',
    5: '',
    56: '0xfB0E714128fC6E94acbfD771d9a35d808A7a81E5',
    97: '0xCdCf27F52d3A4C34637a0d4805B646f8B80F00d3'
  },
  claimHandlerContr: {
    1: '',
    5: '',
    56: '0x6308B4F5Ec9384d1B5241b39c57016f167da12Ec',
    97: ''
  },
}
