import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StyledText } from 'components/GlobalStyle'
import { Flex, Grid } from '@mantine/core'
import ContentWrapper from '../ContentWrapper'

const RoundCommonPrizePool: React.FC = () => {
  const intl = useIntl()

  const prizeContent = [
    {
      id: 1,
      title: intl.formatMessage({ id: 'label-tbox-2', defaultMessage: 'T-Box 2' }),
      prizes: [
        { label: intl.formatMessage({ id: "label-1st-place", defaultMessage: "1st Place" }), value: '888' },
        { label: intl.formatMessage({ id: "label-2nd-place", defaultMessage: "2nd Place" }), value: '488' },
        { label: intl.formatMessage({ id: "label-3rd-place", defaultMessage: "3rd Place" }), value: '288' },
      ],
      special: intl.formatMessage({ id: 'hiw-4-content-3', defaultMessage: '150 USDT for 77 lucky MetaTreasure Holders' })
    },
    {
      id: 2,
      title: intl.formatMessage({ id: 'label-tbox-3', defaultMessage: 'T-Box 3' }),
      prizes: [
        { label: intl.formatMessage({ id: "label-1st-place", defaultMessage: "1st Place" }), value: '6,888' },
        { label: intl.formatMessage({ id: "label-2nd-place", defaultMessage: "2nd Place" }), value: '3,888' },
        { label: intl.formatMessage({ id: "label-3rd-place", defaultMessage: "3rd Place" }), value: '1,888' },
      ],
      special: intl.formatMessage({ id: 'hiw-4-content-4', defaultMessage: '888 USDT for 77 lucky MetaTreasure Holders' })
    },
    {
      id: 3,
      title: intl.formatMessage({ id: 'label-tbox-4', defaultMessage: 'T-Box 4' }),
      prizes: [
        { label: intl.formatMessage({ id: "label-1st-place", defaultMessage: "1st Place" }), value: '12,888' },
        { label: intl.formatMessage({ id: "label-2nd-place", defaultMessage: "2nd Place" }), value: '8,888' },
        { label: intl.formatMessage({ id: "label-3rd-place", defaultMessage: "3rd Place" }), value: '6,888' },
      ],
      special: intl.formatMessage({ id: 'hiw-4-content-5', defaultMessage: '1,888 USDT for 77 lucky MetaTreasure Holders' })
    }
  ]

  return (
    <>
      <ContentWrapper title={intl.formatMessage({ id: 'hiw-4-title', defaultMessage: 'Round Common Prize Pool' })}>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="hiw-4-subtitle-1"
            defaultMessage="After every round there will be a Round Common Prize Pool, to be shared among 80 lucky hunters."
          />
        </StyledText>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="hiw-4-subtitle-2"
            defaultMessage="For example T-Box 2 category will have a total of 13,214 USDT to be allocated for this round common prize pool."
          />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={16}>
          <FormattedMessage id='hiw-4-content-1'
            defaultMessage="The prize winners will be selected by random based on the common NFT digital reference number which is assigned to every individual NFTs. All common MetaTreasure NFT holders will have a fair and equal chance at winning our Round Common Prize Pool. We will be utilizing Chainlink, a blockchain-based decentralized randomizer to help generated the winners, this will ensure fairness in all winners without any modifications or interference to the data."
          />
        </StyledText>

        <Grid gutter={50} gutterLg={50} gutterSm={50} mt='30px !important'>
          {prizeContent.map(item =>
          <Grid.Col key={item.id} span={12} md={4} lg={4} sm={6} xs={12} >
            <Flex direction='column'>
              <StyledText fw={600} size='clamp(18px, 4vw, 20px)' tt='uppercase'>
                {item.title}
              </StyledText>

              <Flex direction='column' gap={5} mt={5}>
                {item.prizes.map(prize =>
                <Flex gap={16} justify='space-between'>
                  <StyledText fw={600} size='clamp(16px, 4vw, 17px)' >
                    {prize.label}
                  </StyledText>
                  <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' >
                    {prize.value} USDT
                  </StyledText>
                </Flex>)}
              </Flex>

              <div style={{ marginTop: '32px' }}>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' >
                  <FormattedMessage id="label-special"
                    defaultMessage="Special"
                  />
                </StyledText>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' >
                  {item.special}
                </StyledText>
              </div>
            </Flex>
          </Grid.Col>)}
        </Grid>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id='hiw-4-content-2' defaultMessage="May the odds ever be in your favour hunters! Let's go out and hunt for more treasures!" />
        </StyledText>
      </ContentWrapper>
    </>
  )
}

export default RoundCommonPrizePool