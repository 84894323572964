import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StyledText } from 'components/GlobalStyle'
import ContentWrapper from '../ContentWrapper'

const Bridging: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <ContentWrapper title={intl.formatMessage({ id: 'gs-3-title', defaultMessage: 'Bridging Assets from Ethereum to PulseChain: A Comprehensive Guide' })}>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="gs-3-subtitle-1"
            defaultMessage="The landscape of blockchain technology is continually evolving, offering innovative solutions for scalability, interoperability, and user engagement. One significant development in this arena is PulseChain, a new blockchain aiming to improve upon Ethereum’s limitations."
          />
          &nbsp;<FormattedMessage id="gs-3-subtitle-1a"
            defaultMessage="As PulseChain gains traction, the need to bridge assets from Ethereum to PulseChain becomes increasingly important for users seeking to leverage the benefits of both networks. This article explores the concept of bridging assets and provides a step-by-step guide on how to do it effectively."
          />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' mt={32} mb={16}>
          <FormattedMessage id='gs-3-title-1' defaultMessage='Understanding the Need for Bridging' />
        </StyledText>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={16}>
          <FormattedMessage id='gs-3-content-1' defaultMessage="Ethereum, the second-largest blockchain by market capitalization, has been the backbone for numerous decentralized applications (dApps) and projects. However, it suffers from high transaction fees and network congestion. PulseChain promises faster transaction speeds and lower costs by providing a more efficient consensus mechanism and increasing transaction throughput."
          />
          &nbsp;<FormattedMessage id='gs-3-content-1a' defaultMessage="Bridging assets from Ethereum to PulseChain allows users to enjoy these benefits without abandoning their existing Ethereum-based assets and applications."
          />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' mt={32} mb={16}>
          <FormattedMessage id='gs-3-title-2' defaultMessage='What is a Blockchain Bridge?' />
        </StyledText>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={16}>
          <FormattedMessage id='gs-3-content-2' defaultMessage="A blockchain bridge is a protocol that enables the transfer of tokens and data between two distinct blockchain networks. It ensures that assets can move seamlessly from one blockchain to another while maintaining their value and utility. In the case of Ethereum and PulseChain, the bridge ensures that ERC-20 tokens (standard tokens on Ethereum) can be used on PulseChain without any loss of functionality."
          />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' mt={32} mb={16}>
          <FormattedMessage id='gs-3-title-3' defaultMessage='Steps to Bridge Assets from Ethereum to PulseChain' />
        </StyledText>
        <ol className='grey-marker' type='1'>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-3-list-1' defaultMessage='Set Up Your Wallets' />
            </StyledText>
            <ul style={{ listStyleType: 'circle' }}>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-3-list-1-1' defaultMessage="Ethereum Wallet: Ensure you have an Ethereum-compatible wallet, such as MetaMask, with your ERC-20 tokens." />
                </StyledText>
              </li>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-3-list-1-2' defaultMessage="PulseChain Wallet: Set up a PulseChain-compatible wallet. Currently, PulseChain can also be accessed through MetaMask by adding the PulseChain network." />
                </StyledText>
              </li>
            </ul>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-3-list-2' defaultMessage='Add PulseChain Network to MetaMask' />
            </StyledText>
            <ul style={{ listStyleType: 'circle' }}>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-3-list-2-1' defaultMessage='Open MetaMask, click on the network dropdown, and select "Custom RPC".' />
                </StyledText>
              </li>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-3-list-2-2' defaultMessage='Enter the PulseChain network details ' />
                </StyledText>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='#338F4F' ml={20} mt={20} lts={0.8}>
                  <FormattedMessage id='gs-3-list-2-2-a' defaultMessage="Network Name: <redSpan>PulseChain</redSpan>{br}New RPC URL: <redSpan>https://rpc.pulsechain.com</redSpan>{br}Chain ID: <redSpan>369</redSpan>{br}Currency Symbol: <redSpan>PLS</redSpan>{br}Block Explorer URL: <redSpan>https://scan.pulsechain.com</redSpan>"
                    values={{
                      br: <br />,
                      redSpan: chunk => <span style={{ color: '#A4225B' }}>{chunk}</span>
                    }}
                  />
                </StyledText>
              </li>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-3-list-2-3' defaultMessage='Save and switch to the PulseChain network.' />
                </StyledText>
              </li>
            </ul>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-3-list-3' defaultMessage='Choose a Bridge Service' />
            </StyledText>
            <ul style={{ listStyleType: 'circle' }}>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-3-list-3-1' defaultMessage="Several bridge services facilitate the transfer of assets between Ethereum and PulseChain. Research and select a reputable bridge platform that supports this functionality. Among popular choices, MetaTreasure highly recommends using the official pulsechain bridge which can be found <redirect>here</redirect>."
                    values={{
                      redirect: chunk =>
                        <a style={{ color: 'var(--whiteColor)' }}
                          href='https://bridge.pulsechain.com/'
                          target='_blank' rel='noreferrer noopener'
                        >
                          {chunk}
                        </a>
                    }}
                  />
                </StyledText>
              </li>
            </ul>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-3-list-4' defaultMessage='Initiate the Transfer' />
            </StyledText>
            <ul style={{ listStyleType: 'circle' }}>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-3-list-4-1' defaultMessage='Go to the selected bridge service and connect your Ethereum wallet.' />
                </StyledText>
              </li>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-3-list-4-2' defaultMessage='Select the token you want to transfer and enter the amount.' />
                </StyledText>
              </li>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-3-list-4-3' defaultMessage='Provide your PulseChain wallet address to receive the bridged tokens.' />
                </StyledText>
              </li>
            </ul>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-3-list-5' defaultMessage='Confirm the Transaction' />
            </StyledText>
            <ul style={{ listStyleType: 'circle' }}>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-3-list-5-1' defaultMessage='Approve the transaction in your Ethereum wallet. Be aware of the gas fees, as transferring tokens from Ethereum can still incur significant costs.' />
                </StyledText>
              </li>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-3-list-5-2' defaultMessage='The bridge will lock your ERC-20 tokens on Ethereum and mint equivalent tokens on PulseChain.' />
                </StyledText>
              </li>
            </ul>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-3-list-6' defaultMessage='Verify Receipt on PulseChain' />
            </StyledText>
            <ul style={{ listStyleType: 'circle' }}>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-3-list-6-1' defaultMessage='Once the transaction is complete, check your PulseChain wallet to confirm receipt of the bridged tokens.' />
                </StyledText>
              </li>
              <li>
                <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
                  <FormattedMessage id='gs-3-list-6-2' defaultMessage='You should now see the equivalent tokens on PulseChain, ready for use in the PulseChain ecosystem.' />
                </StyledText>
              </li>
            </ul>
          </li>
        </ol>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' mt={32} mb={16}>
          <FormattedMessage id='gs-3-title-4' defaultMessage='Benefits of Bridging to PulseChain' />
        </StyledText>
        <ul className='grey-marker' style={{ listStyleType: 'disc' }}>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={16}>
              <FormattedMessage id='gs-3-list-7' defaultMessage="Lower Transaction Fees: PulseChain offers significantly lower transaction fees compared to Ethereum, making it cost-effective for users."
              />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={16}>
              <FormattedMessage id='gs-3-list-8' defaultMessage="Faster Transactions: Enjoy quicker transaction confirmations and a more responsive network."
              />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={16}>
              <FormattedMessage id='gs-3-list-9' defaultMessage="Enhanced Scalability: PulseChain's improved scalability allows for a higher volume of transactions without network congestion."
              />
            </StyledText>
          </li>
        </ul>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' mt={32} mb={16}>
          <FormattedMessage id='label-conclusion' defaultMessage='Conclusion' />
        </StyledText>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={16}>
          <FormattedMessage id='gs-3-content-3' defaultMessage="Bridging assets from Ethereum to PulseChain is a strategic move for users looking to capitalize on the advantages of both networks. By following the outlined steps, you can efficiently transfer your ERC-20 tokens to PulseChain, enabling you to participate in the growing PulseChain ecosystem. As blockchain technology continues to advance, interoperability solutions like bridging will play a crucial role in fostering a more connected and versatile decentralized landscape."
          />
        </StyledText>
      </ContentWrapper>
    </>
  )
}

export default Bridging