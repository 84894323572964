import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StyledText } from 'components/GlobalStyle'
import { Image } from '@mantine/core'
import ContentWrapper from '../ContentWrapper'

const CreatePulseWallet: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <ContentWrapper title={intl.formatMessage({ id: 'gs-6-title', defaultMessage: 'How to create a PulseWallet' })}>
        <Image src='/images/helpdesk/getting-started/gs-6-pic1.jpg' alt='PulseWallet' mt={32} mb={30} />

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="gs-6-subtitle-1"
            defaultMessage="We’ve previously discussed using MetaMask, today let us show you another alternative Blockchain Wallet that MetaTreasure supports - ThePulseWallet."
          />
        </StyledText>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="gs-6-subtitle-2"
            defaultMessage="With Pulse Wallet, you are offered various features, including"
          />:
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id='gs-6-title-1' defaultMessage='EASY LOGIN' />:
        </StyledText>
        <ul className='grey-marker' style={{ listStyleType: "'- '" }}>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-6-list-1' defaultMessage='Import Wallet: import your existing wallet using 12 or 24 seed phrases.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-6-list-2' defaultMessage='Hardware Wallet: import your hardware wallet into the Pulse Wallet extension for easy and secure transactions.' />
            </StyledText>
          </li>
        </ul>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id='gs-6-title-2' defaultMessage='ESSENTIAL SECURITY' />:
        </StyledText>
        <ul className='grey-marker' style={{ listStyleType: "'- '" }}>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-6-list-3' defaultMessage='View transaction content, risk check, and estimated balance changes before signing.' />
            </StyledText>
          </li>
        </ul>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id='gs-6-title-3' defaultMessage='NETWORKS' />:
        </StyledText>
        <ul className='grey-marker' style={{ listStyleType: "'- '" }}>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-6-list-4' defaultMessage='Seamlessly switch between PulseChain and other supported EVM chains.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-6-list-5' defaultMessage='Display tokens corresponding to your selected Network.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-6-list-6' defaultMessage='Automatically switch chains corresponding to the active Dapps and websites.' />
            </StyledText>
          </li>
        </ul>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id='gs-6-title-4' defaultMessage='KEEP TRACK OF YOUR ASSETS' />:
        </StyledText>
        <ul className='grey-marker' style={{ listStyleType: "'- '" }}>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-6-list-7' defaultMessage='Store all tokens on PulseChain and other EVM chains.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-6-list-8' defaultMessage='Add any custom tokens you need if they are not in the default list.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-6-list-9' defaultMessage='Support a wide range of token networks.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-6-list-10' defaultMessage='View both NFTs and fungible tokens on PulseChain.' />
            </StyledText>
          </li>
        </ul>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id='gs-6-title-5' defaultMessage='SEND AND RECEIVE TOKENS' />:
        </StyledText>
        <ul className='grey-marker' style={{ listStyleType: "'- '" }}>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-6-list-11' defaultMessage='Send your tokens to anyone using a wallet address, anywhere in the world, and anytime you wish.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-6-list-12' defaultMessage='Receive tokens just by sharing your wallet address.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-6-list-13' defaultMessage='The whitelist feature makes it more convenient to send tokens to frequent addresses.' />
            </StyledText>
          </li>
        </ul>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' mt={32}>
          <FormattedMessage id="gs-6-subtitle-3"
            defaultMessage="There are plenty of benefits to using ThePulseWallet, to get started, here are some simple steps to follow."
          />
        </StyledText>

        <Image src='/images/helpdesk/getting-started/gs-6-pic2.jpg' alt='PulseWallet Banner' mt={32} mb={30} />
      </ContentWrapper>

      <ContentWrapper title={intl.formatMessage({ id: 'gs-6a-title', defaultMessage: 'PulseWallet Setup Guide' })}>
        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' mt={32} mb={16}>
          <FormattedMessage id='label-setup-guide-android-ios' defaultMessage='Setup Guide (Android/iOS)' />
        </StyledText>
        <ol className='grey-marker' type='1'>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-6-list-14' defaultMessage='Download ThePulseWallet via <redirectPlay>Google Play Store</redirectPlay> or <redirectAppStore>AppStore</redirectAppStore>'
                values={{
                  redirectPlay: chunk =>
                    <a className='hover-underline' style={{ color: 'var(--whiteColor)' }}
                      href='https://play.google.com/store/apps/details?id=com.wallet.thepulse&pli=1'
                      target='_blank' rel='noreferrer noopener'
                    >
                      {chunk}
                    </a>,
                  redirectAppStore: chunk =>
                  <a className='hover-underline' style={{ color: 'var(--whiteColor)' }}
                    href='https://apps.apple.com/vn/app/pulse-wallet/id6458346951'
                    target='_blank' rel='noreferrer noopener'
                  >
                    {chunk}
                  </a>
                }}
              />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-6-list-15' defaultMessage='Hit the sign-up button and key in your preferred details.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='mnemonic-warning'
                defaultMessage='<whiteSpan>IMPORTANT</whiteSpan><redSpan>*</redSpan> - Take note of your Mnemonic Phrase as losing it will mean that you will not be able to recover your account if you forget your password.'
                values={{
                  whiteSpan: chunk => <span style={{ color: 'var(--whiteColor)' }}>{chunk}</span>,
                  redSpan: chunk => <span style={{ color: 'red' }}>{chunk}</span>,
                }}
              />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-6-list-16' defaultMessage='And that’s it you’re done' />
            </StyledText>
          </li>
        </ol>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' mt={32} mb={16}>
          <FormattedMessage id='label-setup-guide-chrome' defaultMessage='Setup Guide (Chrome)' />
        </StyledText>

        <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)'>
          <FormattedMessage id='gs-6-subtitle-4' defaultMessage='Start by visiting <redirect>ThePulseWallet</redirect>'
            values={{
              redirect: chunk =>
                <a className='hover-underline' style={{ color: 'var(--whiteColor)' }}
                  href='https://chromewebstore.google.com/detail/pulse-wallet-chromium/ciojocpkclfflombbcfigcijjcbkmhaf?pli=1'
                  target='_blank' rel='noreferrer noopener'
                >
                  {chunk}
                </a>
            }}
          />
        </StyledText>

        <ol className='grey-marker' type='1'>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-6-list-17' defaultMessage='You will be brought to the Chrome web store, and click on the add PulseWallet extension button.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='gs-6-list-18' defaultMessage='Hit the sign-up button on your PulseWallet extension and key in your preferred details.' />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='mnemonic-warning'
                defaultMessage='<whiteSpan>IMPORTANT</whiteSpan><redSpan>*</redSpan> - Take note of your Mnemonic Phrase as losing it will mean that you will not be able to recover your account if you forget your password.'
                values={{
                  whiteSpan: chunk => <span style={{ color: 'var(--whiteColor)' }}>{chunk}</span>,
                  redSpan: chunk => <span style={{ color: 'red' }}>{chunk}</span>,
                }}
              />
            </StyledText>
          </li>
          <li>
            <StyledText fw={600} size='clamp(16px, 4vw, 17px)' color='var(--textGrey)' ml={20}>
              <FormattedMessage id='all-set-msg' defaultMessage='And that’s it, you’re all set.' />
            </StyledText>
          </li>
        </ol>
      </ContentWrapper>
    </>
  )
}

export default CreatePulseWallet